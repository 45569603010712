import "@/scss/style.scss"

import "bootstrap/dist/js/bootstrap.min.js"
import "jquery-viewport-checker/src/jquery.viewportchecker.js"

(function ($, undefined) {
    "use strict";
    var global = {};

    global.init = function () {
        global.menuWidth();
        global.videoMobile();
        global.scrollToSection();
        global.arrowBottomShow();
        global.hamburgerAnimation();
        global.slick();
        global.playStopVideo();
        global.workflowNavArrows();
        global.switchOffNavArrows();
        global.viewPortChecker();
        global.dynamicNumber();
        global.loaders();
        global.cookies();
        global.showFilms();
        AOS.init({
            once: 'true',
            disable: 'mobile',
        });
    };
    global.menuWidth = function () {
        var windowWidth = $(window).width();
        if (windowWidth < 992) {
            $('#menu__Navbar').removeClass('container').addClass('container-fluid');
        }
    };
    global.videoMobile = function () {
        var video = $('#hero');
        var windowWidth = $(window).width();

        if (windowWidth < 992) {
            video.append("<source src='./assets/videos/hero/hero_mobile.mp4' type='video/mp4' >");
        } else {
            video.append("<source src='./assets/videos/hero/hero_compressed.mp4' type='video/mp4' >");
        }
    };

    global.scrollToSection = function () {
        var windowWidth = $(window).width();
        let additionalTopElementMargin = 0
        if (windowWidth < 992) {
            additionalTopElementMargin = 80;
        } else {
            additionalTopElementMargin = 50;
        }
        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - additionalTopElementMargin
            }, 1000);
        });
    };

    global.arrowBottomShow = function () {
        let windowWidth = $(window).width();
        let arrowBottom = $('.arrow-bottom')
        if (windowWidth > 992) {
            $(window).scroll(function (event) {
                var scroll = $(window).scrollTop();
                if (scroll > 350){
                    arrowBottom.fadeIn(800);
                } else{
                    arrowBottom.fadeOut(800);
                }
            });
        } else {
            arrowBottom.hide();
        }
    };

    global.hamburgerAnimation = function () {
        var navMain = $(".navbar-collapse");
        $('#main-menu-mobile').click(function () {
            $('#main-menu-mobile').toggleClass('active');
            $('#main-menu').toggleClass('active');
        });
        navMain.on("click", "a", null, function () {
            $('#main-menu-mobile').toggleClass('active');
            $('#main-menu').toggleClass('active');
            navMain.toggleClass("show");
        });
    };

    global.slick = function () {
        $('.ads__iphone__slider').slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            mobileFirst: true,
            prevArrow: "<div class='ads__slick-prev-wrapper'><img class='slick-prev' src='/assets/icons/left.png'></div>",
            nextArrow: "<div class='ads__slick-next-wrapper'><img class='slick-next' src='/assets/icons/right.png'></div>",
            responsive: [
                {
                    breakpoint: 992,
                    settings: "unslick"

                }]

        });
        $('.final__slick__items').slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            prevArrow: "<div class='final__slick-prev-wrapper'><img class='slick-prev' src='/assets/icons/left.png'></div>",
            nextArrow: "<div class='final__slick-next-wrapper'><img class='slick-next' src='/assets/icons/right.png'></div>",
        });

        $('.type-of-ads__slick-container').slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            mobileFirst: true,
            prevArrow: "<div class='type-of-ads__slick-prev-wrapper'><img class='slick-prev' src='/assets/icons/left.png'></div>",
            nextArrow: "<div class='type-of-ads__slick-next-wrapper'><img class='slick-next' src='/assets/icons/right.png'></div>",
            responsive: [
                {
                    breakpoint: 992,
                    settings: "unslick"

                }
            ]

        });

        $('.masonry').slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            mobileFirst: true,
            prevArrow: "<div class='masonry__slick-prev-wrapper'><img class='slick-prev' src='/assets/icons/left.png'></div>",
            nextArrow: "<div class='masonry__slick-next-wrapper'><img class='slick-next' src='/assets/icons/right.png'></div>",
            responsive: [
                {
                    breakpoint: 992,
                    settings: "unslick"

                }
            ]

        });
    };

    global.playStopVideo = function () {
        $('.video').parent().click(function () {
            if ($(this).children(".video").get(0).paused) {
                $(this).children(".video").get(0).play();
                $(this).children(".video__playpause").hide();
            } else {
                $(this).children(".video").get(0).pause();
                $(this).children(".video__playpause").show();
            }
        })
    };

    global.workflowNavArrows = function () {
        let windowWidth = $(window).width();

        if (windowWidth < 992) {
            $('.workflow__items').css('width', windowWidth * 4);
            $('.workflow__items').css('right', '0');
            $('.workflow__items__button__next').click(function () {
                let currentPosition = parseInt($('.workflow__items').css('right').slice(0, -2));
                let nextPosition = windowWidth + currentPosition + "px";
                $('.workflow__items').css('right', nextPosition);
            });

            $('.workflow__items__button__prev').click(function () {
                let currentPosition = parseInt($('.workflow__items').css('right').slice(0, -2));
                let nextPosition = currentPosition - windowWidth + "px";
                $('.workflow__items').css('right', nextPosition);

            });
        };
    };

    global.switchOffNavArrows = function () {
        let marginCorrection = 20;
        let slideWidth = $('.workflow__items').width() / 4;

        $('.workflow__items__button__prev').click(function () {
            let currentPosition = parseInt($('.workflow__items').css('right').slice(0, -2));

            if (currentPosition === slideWidth || currentPosition <= slideWidth + marginCorrection) {
                $('.workflow__items__button__prev').addClass('disabled');
            }
            $('.workflow__items__button__next').removeClass('disabled');
        });

        $('.workflow__items__button__next').click(function () {
            let currentPosition = parseInt($('.workflow__items').css('right').slice(0, -2));

            $('.workflow__items__button__prev').removeClass('disabled');
            if (currentPosition >= slideWidth * 2 - marginCorrection) {
                $('.workflow__items__button__next').addClass('disabled');

            }
        });
    };

    global.viewPortChecker = function () {
        $('.line').viewportChecker({
            classToAdd: 'animation',
        });
        $('.dynamic-percents__number').viewportChecker({
            classToAdd: 'animation',
        });
    };

    global.dynamicNumber = function () {

        let number = $('.dynamic-percents__number');
        let numberText = number.text();
        number.css('opacity', '1');
        number.prev('.dynamic-percents__plus').css('opacity', '1');
        number.next('.dynamic-percents__percents').css('opacity', '1');
        $({numberValue: 0}).animate({numberValue: numberText}, {
            duration: 3000,
            easing: 'linear',
            progress: function () {
                number.text(Math.ceil(this.numberValue * 10) / 10);
            }
        });
    };

    global.loaders = function () {
        $('.video').on('waiting', function (event) {
            $(this).parent().children(".loader").addClass('loading');
        });
        $('.video').on('canplay', function (event) {
            $(this).parent().children(".loader").removeClass('loading');
        });
    };

    global.cookies = function () {
        let hasLocalStorage = true;
        setTimeout(function () {
            let cookies = $(".cookies")[0];
            // noinspection EqualityComparisonWithCoercionJS
            if (!hasLocalStorage || localStorage.getItem("cookies") === null || localStorage.getItem("cookies") != 1) {
                cookies.classList.add("active");
                cookies.querySelector(".content-wrapper").classList.add("active")
            }
            $(".btn-accept-cookies")[0].addEventListener("click", function () {
                if (hasLocalStorage) {
                    localStorage.setItem("cookies", "1");
                }
                cookies.querySelector(".content-wrapper").classList.remove("active");
                setTimeout(function () {
                    cookies.classList.remove("active");
                }, 300)
            })
        }, 2000);
    };

    global.showFilms = function () {
        const btnShowAll = document.querySelector(".btn-masonry");
        const btnShowAllText = btnShowAll.querySelector("span");
        const btnShowAllArrow = btnShowAll.querySelector(".arrow-purple");
        const masonry = document.querySelector(".masonry");
        const gradientContainer = document.querySelector(".gradient__container");
        btnShowAll.addEventListener("click", function () {
            btnShowAll.classList.toggle("btn-masonry-show");
            masonry.classList.toggle("show__less");
            gradientContainer.classList.toggle("d-none");
            btnShowAllArrow.classList.toggle("rotated");
            if (btnShowAllText.innerText === "SHOW ALL" ){
                btnShowAllText.innerHTML = "SHOW LESS"
            } else {
                btnShowAllText.innerHTML = "SHOW ALL"
            }
        })
    };

    $(document).ready(function () {
        global.init();
    });

}(jQuery));
